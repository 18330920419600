import React, { Suspense, useRef, useState } from "react";
import { GlobalCanvas, SmoothScrollbar } from "@14islands/r3f-scroll-rig";
import { Environment, Loader } from "@react-three/drei";
import { IKContext, IKImage, IKVideo } from "imagekitio-react";
import { BodyCopy, Headline, Subtitle } from "./Text";
import { Image } from "./Image";
import { ImageCube } from "./ImageCube";
import { WebGLBackground } from "./WebGLBackground";
import { Lens } from "./Lens";
import CodropsFrame from "./CodropsFrame";
import EffectsToggle from "./EffectsToggle";

import "@14islands/r3f-scroll-rig/css";
import { Video } from "./Video";

// Photos by <a href="https://unsplash.com/@maxberg?utm_source=unsplash&utm_medium=referral&utm_content=creditCopyText">Maxim Berg</a> on <a href="https://unsplash.com/photos/u8maxDvbae8?utm_source=unsplash&utm_medium=referral&utm_content=creditCopyText">Unsplash</a>

export default function Home() {
  const eventSource = useRef();
  const [enabled, setEnabled] = useState(true);

  return (
    // We attach events on parent div in order to get events on both canvas and DOM
    <div ref={eventSource}>
      <IKContext
        publicKey="public_rTChKcRC+/SoVStDt4BOpprAXZk="
        urlEndpoint="https://ik.imagekit.io/openersdotapp"
        transformationPosition="path"
        // authenticationEndpoint="http://www.yourserver.com/auth"
      >
        {/* <CodropsFrame /> */}
        <GlobalCanvas
          // shader errors are hidden by default which speeds up compilation
          debug={false}
          // scaleMultiplier is a scroll-rig setting to scale the entire scene
          scaleMultiplier={0.01}
          // All other props on the R3F Canvas are supported:
          eventSource={eventSource}
          eventPrefix="client"
          flat // disable toneMapping since we have editorial images
          camera={{ fov: 14 }}
          style={{ pointerEvents: "none", zIndex: -1 }}
        >
          {(globalChildren) => (
            <Lens>
              <WebGLBackground />
              <Suspense fallback="">
                {/* 
                Our materials use PBR lighting and requires an environment
              */}
                <Environment files="env/empty_warehouse_01_1k.hdr" />
                {globalChildren}
              </Suspense>
            </Lens>
          )}
        </GlobalCanvas>
        <SmoothScrollbar
          enabled={enabled}
          config={{ syncTouch: true }} // Lenis setting to force smooth scroll on touch devices
        />
        <article>
          <EffectsToggle setEnabled={setEnabled} enabled={enabled} />
          <header className="container">
            <div className="headerLayout">
              <div className="video-container">
                {/* <Video
                  path="openers-hero-test.MP4"
                  width="90%"
                  aspectRatio={16 / 9}
                  autoPlayMode="on-scroll"
                  loop={true}
                  muted={true}
                  controls={false}
                /> */}
              </div>
              <h2>
                <Headline wobble>YOUR CONVERSATION TOOL</Headline>
              </h2>
              <BodyCopy as="p" className="subline">
                Discover a beautifully crafted app that sparks engaging
                conversations with unique, open-ended questions. Elevate your
                interactions and connect more deeply with conversation starters
                tailored just for you.
              </BodyCopy>
            </div>
          </header>
          <section className="container">
            <Image src="images/openers-mock-6.png" className="ImageLandscape" />
          </section>
          <section className="container">
            <h3>
              <Subtitle>Openers works offline and is not AI-powered,</Subtitle>
              <em>
                <Subtitle>
                  so you never have to wait for responses or deal with slow
                  internet connections.
                </Subtitle>
              </em>
            </h3>
            <p>
              <BodyCopy>
                Enjoy smooth, interactive conversation prompts wherever you go.
              </BodyCopy>
            </p>
          </section>
          <section className="ParallaxContainer">
            <Image
              src="images/openers-mock-5.png"
              className="aspect-9_13"
              parallaxSpeed={1.08}
            />
            <Image
              src="images/openers-mock-4.png"
              className="aspect-16_11"
              parallaxSpeed={0.92}
            />
          </section>
          <section className="container">
            <h4>
              <BodyCopy>
                Explore diverse conversation categories to connect and engage.
              </BodyCopy>
            </h4>
          </section>
          <section>
            <ImageCube
              src="images/openers-mock-3.png"
              className="JellyPlaceholder"
            />
          </section>
          <section className="container">
            <h3>
              <Subtitle>
                Create meaningful connections with our offline-friendly app.
              </Subtitle>
              <em>
                <Subtitle>
                  Perfect for all occasions – friends, family, dating, and more.
                </Subtitle>
              </em>
            </h3>
            <p>
              <a href="https://github.com/14islands/r3f-scroll-rig">
                <BodyCopy>
                  Start conversations that matter anytime, anywhere.
                </BodyCopy>
              </a>
            </p>
          </section>
          <footer>{/* <CodropsFrame /> */}</footer>
        </article>

        <Loader
          containerStyles={{
            background: "transparent",
            top: "auto",
            bottom: 0,
            height: "5px",
          }}
          innerStyles={{ background: "white", width: "100vw", height: "5px" }}
          barStyles={{ background: "#6e6bcd", height: "100%" }}
        />
      </IKContext>
    </div>
  );
}
